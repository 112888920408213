/* eslint-disable id-length */

import React, { useEffect } from 'react';
import PropTypes, { string, number } from 'prop-types';
import {
  params,
  shape,
  fragment,
  string as stringType,
  useDataModel,
  customType,
  arrayOf,
} from '@thd-nucleus/data-sources';
import { HotspotBlock } from './HotspotBlock';

const ImageHotspot = ({ componentId, componentClass, position }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('image-hotspot.ready'); }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  if (error) {
    return null;
  }

  if (loading) return <h1>Loading...</h1>;

  const hotspots = JSON.parse(data?.component?.hotspots);
  const hotspotArr = hotspots.hotspots.reverse();
  const actions = data?.component?.hotspotActions;
  const image = data?.component?.image?.damContentSelector?.assetData[0]?.selectedImageUrl;

  return (
    <div className="sui-relative sui-mx-auto sui-my-0" data-component="ImageHotspot">
      {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
      <img
        className="sui-w-full"
        src={image}
        alt={data?.component?.title}
      />
      {
        hotspotArr.map((hotspot, idx) => {
          const hotspotStyle = {
            width: `${hotspot.width}%`,
            height: `${hotspot.height}%`,
            left: `${hotspot.x}%`,
            top: `${hotspot.y}%`,
          };
          const action = actions.filter((actionItem) => actionItem.title === hotspot.name);
          return (
            <HotspotBlock
              key={`hotspot-${idx}-${action[0].id}`}
              id={action[0].id}
              position={position}
              style={hotspotStyle}
              action={action[0].url}
              name={hotspot.name}
            />
          );
        })
      }
    </div>
  );
};

ImageHotspot.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  position: PropTypes.oneOfType([string, number]),
};

ImageHotspot.defaultProps = {
  position: 1,
};

ImageHotspot.displayName = 'ImageHotspot';

ImageHotspot.dataModel = {
  component: params({
    componentClass: customType('ComponentClass').enum(['ImageHotspot']).isRequired(),
    id: stringType().isRequired(),
  }).shape({
    ImageHotspot: fragment().shape({
      id: stringType(),
      title: stringType(),
      imageUrl: stringType(),
      image: shape({
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
      }),
      hotspots: stringType(),
      hotspotActions: arrayOf(shape({
        id: stringType(),
        url: stringType(),
        title: stringType(),
      }))
    })
  })
};

export { ImageHotspot, HotspotBlock };
