import React, { useMemo } from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSticker,
  PodSection,
  PodFooter,
  PodSpacer,
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductSponsored,
  ProductRating,
  ProductIdentifier,
  ProductATC
} from '@thd-olt-component-react/product-pod';
import {
  bool as boolType,
  string as stringType,
  number as numType
} from 'prop-types';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { AddToList } from '@thd-olt-component-react/add-to-list';

export const RecsProductPod = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    itemId,
    storeId,
    position,
    parent,
    hideSwatches,
    hideFavorites,
    hideBadge,
    hideATC,
    hideRating,
    scheme,
    anchorProduct,
    preferredPriceFlag,
    brandTitleMaxLine,
    strategy,
    zoneName,
    zonePosition,
    podResults,
    noATCFulfillment
  } = props;

  const merchRec = useMemo(() => {
    return `MERCH=REC-_-${scheme}-_-${itemId}-_-${position}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  }, [itemId, position]);

  const PodDetails = ({ podItemId }) => {
    const padding = zoneName ? ['LEFT', 'RIGHT'] : ['TOP', 'BOTTOM'];
    const Details = () => {
      return (
        <>
          <PodSpacer padding={padding}>
            <ProductHeader
              brand="above"
              itemId={podItemId}
              brandTitleMaxLine={brandTitleMaxLine}
              disableShopThisCollection
              merchQueryParam={merchRec}
            />
          </PodSpacer>
          {!hideRating && (
            <ProductRating itemId={podItemId} hideRating={hideRating} merchQueryParam={merchRec} />
          )}
          <Price
            itemId={podItemId}
            large={false}
            storeId={storeId}
            displayEachUom={false}
            hideSavingsText={!preferredPriceFlag && !zoneName}
            showPreferredPricingBadge
          />
        </>
      );
    };

    if (zoneName) {
      return <Details />;
    }

    return (
      <PodSection columnAlign>
        <Details />
      </PodSection>
    );
  };

  PodDetails.propTypes = {
    podItemId: stringType.isRequired
  };

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      analyticsData={{
        parent, position, scheme, anchorProduct, strategy, zoneName, zonePosition, podResults
      }}
      render={(pod) => (
        <>
          <PodSticker noPadding position="top-left">
            {!hideBadge && <ProductBadge itemId={pod.itemId} storeId={storeId} />}
          </PodSticker>
          {!hideFavorites
            && (
              <PodSticker position="top-right">
                <PodSpacer padding={['RIGHT']}>
                  <AddToList
                    itemId={pod.itemId}
                    storeId={storeId}
                    showIconButton
                  />
                </PodSpacer>
              </PodSticker>
            )}
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
            merchQueryParam={merchRec}
          />
          {!hideSwatches
            && (
              <SuperSkuMiniSwatches
                itemId={pod.itemId}
                storeId={storeId}
                onChange={pod.onChange}
                onHover={pod.onSSKUHover}
              />
            )}
          <PodDetails podItemId={pod.itemId} />
          {!hideATC
            && (
              <PodFooter>
                <PodSpacer minHeight="40px">
                  <ProductATC
                    itemId={pod.itemId}
                    checkGeneric
                    outline
                    noATCFulfillment={noATCFulfillment}
                  />
                </PodSpacer>
              </PodFooter>
            )}
        </>
      )}
    />
  );
};

RecsProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductSponsored,
  ProductRating,
  ProductIdentifier,
  ProductATC,
  Price,
  ProductHeader,
  SuperSkuMiniSwatches,
  AddToList,
);

RecsProductPod.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  storeId: stringType,
  position: numType,
  parent: stringType,
  hideSwatches: boolType,
  hideFavorites: boolType,
  hideBadge: boolType,
  hideATC: boolType,
  hideRating: boolType,
  scheme: stringType,
  anchorProduct: stringType,
  preferredPriceFlag: boolType,
  brandTitleMaxLine: numType,
  strategy: stringType,
  zoneName: stringType,
  zonePosition: stringType,
  podResults: numType,
  noATCFulfillment: boolType
};

RecsProductPod.defaultProps = {
  storeId: '',
  position: '',
  parent: '',
  hideSwatches: true,
  hideFavorites: true,
  hideBadge: false,
  hideATC: false,
  hideRating: false,
  scheme: 'drecs',
  anchorProduct: '',
  preferredPriceFlag: false,
  brandTitleMaxLine: 4,
  strategy: 'project-based',
  zoneName: '',
  zonePosition: '',
  podResults: 0,
  noATCFulfillment: true
};
RecsProductPod.displayName = 'RecsProductPod';
