import React from 'react';
import PropTypes from 'prop-types';
import { useImpression } from '@thd-olt-component-react/impression';

export const HotspotBlock = ({
  id, action, name, style, position
}) => {

  const skuSplit = action.split('/');
  const skuId = skuSplit[skuSplit.length - 1];
  const skuIdSplit = skuId?.split('');
  const skuReturn = () => {
    let returnVal = skuId;
    if (skuIdSplit[0] === 'N') {
      returnVal = skuIdSplit.map((val, n) => {
        if (val === 'N' && n === 0) return 'n-';
        return val;
      });
      return returnVal.join('');
    }
    return returnVal;
  };

  const skuValFinal = skuReturn();

  const { ref, clickID } = useImpression({
    data: {
      id,
      category: skuValFinal,
      position,
      component: 'ImageHotspot',
      name: 'ImageHotspot',
    }
  });

  return (
    <a
      href={action}
      aria-label={`Hotspot Image ${name}`}
      rel="noreferrer"
      className="sui-block sui-cursor-pointer sui-absolute"
      style={style}
      data-testid="hotspot-block"
    >
      <div
        ref={ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
      />
    </a>
  );
};

HotspotBlock.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  style: PropTypes.shape({
    width: PropTypes.string,
    left: PropTypes.string,
    top: PropTypes.string,
    border: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
