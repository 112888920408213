/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, shape, bool, useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import classNames from 'classnames/bind';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { Link } from '@one-thd/sui-atomic-components';
import { getCustomUrlWithAnalytics } from '../../productDetailsUtils';

const CONFIGURABLE_BLINDS = 'CONFIGURABLE_BLINDS';
const BUILD_AND_BUY = 'Build and Buy';
export const ProductHeader = ({
  itemId,
  brand,
  collectionBlock,
  disableShopThisCollection,
  large,
  target,
  brandMaxLine,
  brandTitleMaxLine,
  isIframe,
  iframeAnalyticsData,
  titleMaxLine,
  fullWidth,
  xSmall,
  onClick,
  merchQueryParam
}) => {

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { channel, hosts } = useContext(ExperienceContext);

  const CustomHeaderTag = useConfigService('fs:specialBuyHeaderTag') ? 'div' : 'h3';

  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductDetails',
      name: 'ProductDetails',
      position: 0,
    },
  });

  const { parent } = additionalData || {};

  const [sponsoredValues, setSponsoredValues] = useState({});
  // part of tnt code see note below
  const [newTitleName, setNewTitleName] = useState({});

  const [productState, setProductState] = useState({
    canonicalUrl: '',
    productType: '',
    productLabel: '',
    brandName: '',
  });

  const { data } = useDataModel('product', {
    variables: {
      itemId,
    },
  });

  const { product } = data || {};

  const { details, identifiers, info } = product || {};

  const { collection: collectionObj } = details || {};

  useEffect(() => {
    if (identifiers?.productLabel) {
      setProductState({
        canonicalUrl: identifiers?.canonicalUrl,
        productType: identifiers?.productType,
        productLabel: identifiers?.productLabel,
        brandName: identifiers?.brandName,
      });
    }
  }, [identifiers]);

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata,
      });
    }
  }, [info?.isSponsored, info?.sponsoredMetadata]);

  // this is start of temporary code for tnt take out when tnt is done
  // tnt est start 4/24/23
  const output = useLifeCycleEventBus('product-name_PN120-testing', true);

  useEffect(() => {
    const incomingName = output[itemId];
    if (incomingName) {
      const sampleObj = {};
      sampleObj[itemId] = incomingName;
      setNewTitleName({ ...newTitleName, ...sampleObj });
      setProductState({
        ...productState,
        productLabel: incomingName,
      });
    }
  }, [output, itemId]);
  // end of temp tnt code
  const { name: collectionName, url: collectionUrl } = collectionObj || {};

  const canonicalUrl = identifiers?.canonicalUrl || productState.canonicalUrl;
  const productType = identifiers?.productType || productState.productType;
  const productLabel = newTitleName[itemId] || identifiers?.productLabel || productState.productLabel;
  const brandName = identifiers?.brandName || productState.brandName;

  if (!productLabel) return null;

  const handleClick = (event, action = null) => {
    const customExperience = !!info?.globalCustomConfigurator?.customExperience;
    if (!customExperience && onClick && action !== BUILD_AND_BUY) {
      onClick(event, product, action, sponsoredValues);
    }
    const productHeaderEventData = {
      podAction: action || 'product details',
      podAnchorSku: itemId,
      target,
      parent,
    };

    if (isIframe) {
      window.top.LIFE_CYCLE_EVENT_BUS.trigger('cartItem.DD_EVENT', iframeAnalyticsData);
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productHeaderEventData);
    }
  };

  const shouldDisplayBrand = (location) => {
    return brandName && !brandName.toLowerCase().includes('unbranded') && brand === location;
  };

  const isBuildAndBuyProduct = productType === CONFIGURABLE_BLINDS;

  const isMobile = channel === 'mobile';
  const showShopThisCollectionLink = !disableShopThisCollection && collectionName && collectionUrl;
  const onBuildAndBuyImageClick = (event) => handleClick(event, BUILD_AND_BUY);
  const checkOnClick = isBuildAndBuyProduct ? onBuildAndBuyImageClick : handleClick;

  const productURL = getCustomUrlWithAnalytics({
    canonicalUrl,
    isBuildAndBuyProduct,
    blindsHost: hosts?.customBlinds,
    sponsoredValues: sponsoredValues?.isSponsored
      ? sponsoredValues
      : {
        isSponsored: info?.isSponsored,
        sponsoredMetadata: info?.sponsoredMetadata,
      },
    merchQueryParam: merchQueryParam || null
  });

  const headerTarget = isMobile ? '' : target;

  const titleClasses = classNames('sui-block sui-flex-col sui-mt-1', {
    'sui-text-primary sui-font-regular sui-mb-1 sui-line-clamp-5 sui-text-ellipsis sui-inline':
      brand === 'inline' || brand === 'above',
    'sui-text-lg sui-inline': large,
    'sui-w-full': fullWidth,
  });
  const labelClasses = classNames(
    'sui-text-primary sui-font-regular sui-mb-1 sui-line-clamp-5 sui-text-ellipsis sui-inline',
    {
      'sui-line-clamp-2': titleMaxLine === 2,
      'sui-line-clamp-2 sui-text-xs sui-m-0': xSmall && isMobile,
    }
  );
  const brandClasses = classNames('sui-text-primary sui-font-w-bold', {
    'sui-whitespace-nowrap sui-overflow-hidden sui-text-ellipsis sui-h-full': brandMaxLine === 1,
    'sui-m-0': xSmall && isMobile,
  });
  const shopThisCollectionClasses = classNames('sui-text-primary sui-mt-1', {
    'sui-block': brand === 'inline' && collectionBlock,
  });
  const brandTitleClasses = classNames({
    'sui-inline': !brandTitleMaxLine,
    'sui-overflow-hidden sui-text-ellipsis': !!brandTitleMaxLine,
    'sui-line-clamp-2': brandTitleMaxLine === 2,
    'sui-line-clamp-3': brandTitleMaxLine === 3,
    'sui-line-clamp-4': brandTitleMaxLine === 4,
  });

  return (
    <>
      <div data-testid="product-header" data-component="ProductHeader">
        <Link href={isMounted ? productURL : ''} onClick={checkOnClick} target={headerTarget} underline="hover">
          <div className={brandTitleClasses}>
            <CustomHeaderTag className={titleClasses}>
              {shouldDisplayBrand('above') && (
                <p
                  data-testid="attribute-brandname-above"
                  className="sui-text-primary sui-font-w-bold sui-mb-1"
                >
                  {` ${brandName} `}
                </p>
              )}
              {shouldDisplayBrand('inline') && (
                <span
                  data-testid="attribute-brandname-inline"
                  className="sui-text-primary sui-font-w-bold sui-mr-1"
                >
                  {brandName}
                </span>
              )}
              <span className={labelClasses}>{productLabel}</span>
            </CustomHeaderTag>
          </div>
        </Link>
        {shouldDisplayBrand('below') && (
          <p className={brandClasses}>
            by
            <span
              data-testid="attribute-brandname-above"
              className="sui-text-primary sui-font-w-bold"
            >
              {' '}
              {brandName}
            </span>
          </p>
        )}
      </div>
      {showShopThisCollectionLink && (
        <Link
          classNames={shopThisCollectionClasses}
          href={collectionUrl}
          target={target}
          underline="hover"
        >
          <p data-testid="attribute-shop-this-collection" className="sui-text-xs sui-mt-1">
            Shop this Collection
          </p>
        </Link>
      )}
    </>
  );
};

ProductHeader.propTypes = {
  itemId: PropTypes.string.isRequired,
  /** Position the brand name */
  brand: PropTypes.oneOf(['above', 'below', 'hidden', 'inline']),
  collectionBlock: PropTypes.bool,
  disableShopThisCollection: PropTypes.bool,
  /** Larger text for title */
  large: PropTypes.bool,
  target: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleMaxLine: PropTypes.number,
  brandMaxLine: PropTypes.number,
  brandTitleMaxLine: PropTypes.number,
  iframeAnalyticsData: PropTypes.object,
  isIframe: PropTypes.bool,
  xSmall: PropTypes.bool,
  onClick: PropTypes.func,
  merchQueryParam: PropTypes.string
};

ProductHeader.defaultProps = {
  brand: 'below',
  collectionBlock: true,
  disableShopThisCollection: false,
  large: false,
  target: null,
  fullWidth: false,
  titleMaxLine: null,
  brandMaxLine: null,
  brandTitleMaxLine: null,
  isIframe: false,
  iframeAnalyticsData: null,
  xSmall: false,
  onClick: () => {},
  merchQueryParam: null
};

ProductHeader.displayName = 'ProductHeader';

ProductHeader.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSource: string(),
    details: shape({
      collection: shape({
        name: string(),
        url: string(),
      }),
    }),
    identifiers: shape({
      canonicalUrl: string(),
      brandName: string(),
      productType: string(),
      productLabel: string(),
    }),
    info: shape({
      isSponsored: bool(),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string(),
      }),
      globalCustomConfigurator: shape({
        customExperience: string(),
      }),
    }),
  }),
};
