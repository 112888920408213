/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */

/**
 * Generate a theme based on the options received. Then, pass it as a prop to ThemeProvider.
 *
 * Usage:
 *
 * ```jsx
 * import { createTheme } from '@one-thd/sui-atomic-components';
 * ```
 */
export const createTheme = (options = {}) => {
  const { palette = {} } = options;

  const {
    mode = 'light',
    ...rest
  } = palette;

  const paletteVariables = {};
  const flattenPalette = (obj, name) => {
    for (let key in obj) {
      if (typeof obj[key] == 'object') {
        flattenPalette(obj[key], `${name}-${key}`);
      } else {
        paletteVariables[`${name}-${key}`] = `var(--sui-palette-${obj[key]})`;
      }
    }
  };

  flattenPalette(rest, '--sui-palette');

  return {
    palette: {
      mode,
      modeAttribute: {
        'data-sui-palette': mode,
      },
      ...paletteVariables
    }
  };
};