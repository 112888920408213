/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
import * as React from 'react';
import { Button, Typography } from '@one-thd/sui-atomic-components';
import { PacDrawer, LoadingPacDrawer } from '../../../common/Drawer/PacDrawer/PacDrawer';
import { useOptionsTileGroup } from '../../../common/Drawer/OptionsTile';
import { getImageURL } from '../../../common/utils/cardUtils';
import { PacDrawerContext } from '../../../contexts/PacDrawerContext';
import { findTierByTierId, findTierByPerkId } from '../../../common/utils/ContextUtils';
import { PerkDetailsDrawer } from '../PerksDrawer/PerkDetailsDrawer';
import { windowExists } from '../../../common/utils/AccountUtils';
import { analyticsOverlay, analyticsClick } from '../../../common/utils/AnalyticsUtils';
import { formatSpendUntilThisPerkSubtitle } from '../../../common/utils/DrawerUtils';
import { DRAWER_ANALYTICS, SELECTION_CONSTANTS as CONSTANTS, SUBTITLE_TEXT } from '../../../core/DrawerConstants';
import { ErrorSelectionDrawer, SelectionConfirmationDrawer, useCheckAndFetchPerks } from './SelectionComponents';

// TODO: Complete after Figma design is available
const NotFoundSelectionDrawer = ({ eventOutput }) => (
  <ErrorSelectionDrawer
    eventOutput={eventOutput}
    headerText={CONSTANTS.ERROR.HEADER}
    subTitle={CONSTANTS.CONTENT_HEADER.LOCKED}
  />
);

export const ChooseNewPerkDrawer = (props) => {
  // TODO: Pass perk object from event bus instead. If no data, THEN grab from context
  const { setOpen, infoPerks, info, eventOutput } = React.useContext(PacDrawerContext) ?? {};
  const { tier, eventOutput: eventOutputFromProps } = props;
  const eventOutputSource = eventOutput ?? eventOutputFromProps ?? {};
  const { onBack, onClose } = eventOutputSource ?? {};
  const [payload, setPayload] = React.useState(null);
  const header = CONSTANTS.HEADER.UNLOCKED;
  const title = 'Congratulations!';
  const subTitle = SUBTITLE_TEXT.UNCLAIMED_PERK(tier.maxThresholdDisplay);
  const iconImgUrl = getImageURL('ProTiers/icon-badge-unlocked-square.svg');
  const optionsList = tier?.tierPerks?.[0]?.options ?? [];
  const [detailsDrawerParams, setDetailsDrawerParams] = React.useState({
    showingDetails: false, optionObj: null
  }) ?? {};
  const { OptionsTileGroup, selectedValue } = useOptionsTileGroup({ setDetailsDrawerParams, optionsList });
  const optionObj = optionsList.find((option) => option.tierRewardId === selectedValue);
  const choosePerk = (optionObjForPayload, tierObjForPayload) => {
    setPayload(() => ({ optionObj: optionObjForPayload, tierObj: tierObjForPayload }));
  };
  const handleChoosePerk = () => {
    analyticsClick('pacDrawer',
      DRAWER_ANALYTICS.perkSelectionDrawer.choosePerkButtonFromSelection(optionObj.perkTitle));
    choosePerk(optionObj, tier);
  };
  const handleOnClose = () => {
    setOpen(false);
    if (onClose) onClose();
    if (windowExists()) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
        'loyalty-benefits.perk-selection-drawer__close',
        {},
      );
    }
  };
  const handleOnBack = () => {
    setOpen(false);
    if (onBack) onBack();
    if (windowExists()) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
        'loyalty-benefits.perk-selection-drawer__back',
        {},
      );
    }
  };
  const detailsDrawerProps = {
    optionObj: detailsDrawerParams.optionObj,
    tierObj: tier,
    setDetailsDrawerParams,
    choosePerk,
    onClose,
  };

  const Content = () => {
    return (
      <div>
        <Typography variant="body-base" weight="bold">{CONSTANTS.CONTENT_HEADER.UNLOCKED}</Typography>
        <div className="sui-h-6" />
        <OptionsTileGroup />
      </div>
    );
  };

  const Footer = () => {
    return (
      <div>
        <Button fullWidth disabled={!selectedValue} onClick={handleChoosePerk}>
          Choose This Perk
        </Button>
      </div>
    );
  };

  if (payload) return <SelectionConfirmationDrawer payloadData={payload} eventOutput={eventOutputSource} />;

  if (detailsDrawerParams.showingDetails) return <PerkDetailsDrawer {...detailsDrawerProps} />;
  return (
    <PacDrawer
      headerText={header}
      onBack={onBack ? handleOnBack : null}
      onClose={handleOnClose}
      iconImgUrl={iconImgUrl}
      title={title}
      subTitle={subTitle}
      Content={Content}
      Footer={Footer}
    />
  );

};

export const ViewFuturePerkDrawer = (props) => {
  const [detailsDrawerParams, setDetailsDrawerParams] = React.useState({ showingDetails: false, optionObj: null });
  const { setOpen, info, eventOutput } = React.useContext(PacDrawerContext) ?? {};
  const { tier, eventOutput: eventOutputFromProps } = props;
  const eventOutputSource = eventOutput ?? eventOutputFromProps ?? {};
  const {
    onBack, onClose, pxccUserCategory, accrualData, activePxccFactor
  } = eventOutputSource ?? {};
  const header = CONSTANTS.HEADER.LOCKED;
  const title = `${'$' + tier?.maxThresholdDisplay + ' '}Spend Milestone`;
  const subTitle = formatSpendUntilThisPerkSubtitle({ tier, accrualData, pxccUserCategory, activePxccFactor });
  const iconImgUrl = getImageURL('iconSuiteToggle__ProXtraPerkExpired.svg');
  const optionsList = tier?.tierPerks?.[0]?.options ?? [];
  const { OptionsTileGroup } = useOptionsTileGroup({ setDetailsDrawerParams, optionsList, isLocked: true });
  const handleOnClose = () => {
    setOpen(false);
    if (onClose) onClose();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.perk-selection-drawer__close',
      {},
    );
  };
  const handleOnBack = () => {
    setOpen(false);
    if (onBack) onBack();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.perk-selection-drawer__back',
      {},
    );
  };
  const detailsDrawerProps = {
    optionObj: detailsDrawerParams.optionObj,
    tierObj: tier,
    setDetailsDrawerParams,
    lockedSubtitle: subTitle,
    onClose,
  };

  const content = () => {
    return (
      <div>
        <Typography variant="body-base" weight="bold">{CONSTANTS.CONTENT_HEADER.LOCKED}</Typography>
        <div className="sui-h-6" />
        <OptionsTileGroup />
        <div className="sui-h-20" />
      </div>
    );
  };

  if (detailsDrawerParams.showingDetails) return <PerkDetailsDrawer {...detailsDrawerProps} />;
  return (
    <PacDrawer
      headerText={header}
      onBack={onBack ? handleOnBack : null}
      onClose={handleOnClose}
      iconSvgUrl={iconImgUrl}
      title={title}
      subTitle={subTitle}
      Content={content}
    />
  );

};

export const SelectionDrawer = (props) => {
  const { infoPerks, eventOutput } = React.useContext(PacDrawerContext) ?? {};
  const { checkAndFetchPerks } = useCheckAndFetchPerks();
  const { eventOutput: eventOutputFromProps } = props;
  const eventOutputSource = eventOutput ?? eventOutputFromProps ?? {};
  const { tierObj, tierId, perkId, tierList } = eventOutputSource ?? {};
  const refreshFn = () => infoPerks.getPerksResponse();
  let tier = tierObj;

  React.useEffect(() => {
    if (!tier && !tierList && (tierId || perkId)) checkAndFetchPerks();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.perk-selection-drawer__open',
      {},
    );
  }, []);

  if (!tier && tierId && (tierList || !infoPerks?.isLoadingInfoPerks)) {
    tier = findTierByTierId(tierId, tierList ?? infoPerks?.infoPerksData?.program?.tiers);
  }

  if (!tier && perkId && (tierList || !infoPerks?.isLoadingInfoPerks)) {
    tier = findTierByPerkId(perkId, tierList ?? infoPerks?.infoPerksData?.program?.tiers);
  }

  React.useEffect(() => {
    if (tier) {
      analyticsOverlay('pacDrawer', !tier?.earned
        ? DRAWER_ANALYTICS.perkSelectionDrawer.locked
        : DRAWER_ANALYTICS.perkSelectionDrawer.unlocked
      );
    }
  }, [tier]);

  if (!tier && !tierList && (tierId || perkId) && infoPerks.isLoadingInfoPerks) return <LoadingPacDrawer />;
  if (!tier && infoPerks.infoPerksError) {
    return (
      <ErrorSelectionDrawer
        refreshFn={refreshFn}
        eventOutput={eventOutputSource}
      />
    );
  }
  if (!tier) return <NotFoundSelectionDrawer eventOutput={eventOutputSource} />;
  return !tier?.earned
    ? <ViewFuturePerkDrawer tier={tier} eventOutput={eventOutputSource} />
    : <ChooseNewPerkDrawer tier={tier} eventOutput={eventOutputSource} />;
};
