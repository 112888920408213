import { createTheme } from '@one-thd/sui-atomic-components';

const EndcapBFTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    },
    border: {
      primary: 'brand'
    }
  },
  'sui-text-success': 'red'
});

EndcapBFTheme.palette['--sui-palette-background-primary'] = '73 73 73';
EndcapBFTheme.palette['--sui-palette-border-primary'] = '191 191 191';
EndcapBFTheme.palette['--sui-palette-text-primary'] = '255 255 255';
EndcapBFTheme.palette['--sui-palette-text-subtle'] = '191 191 191';
EndcapBFTheme.palette['--sui-palette-background-inverse'] = '141 141 141';
EndcapBFTheme.palette['--sui-palette-background-strong'] = '255 255 255';

export { EndcapBFTheme };