import React from 'react';
import {
  string,
  shape,
  arrayOf,
  element,
  bool,
  oneOf
} from 'prop-types';
import { LayoutSection } from './LayoutSection';

export const Layout = ({
  layoutType,
  components,
  layoutComponents,
  componentSelectorData,
  loading,
  LoadingLayout,
}) => {
  const heroCarouselIdx = layoutComponents?.findIndex(
    (component) => component?.customRenderId === 'Hero Carousel'
  );
  const heroCarousel = layoutComponents[heroCarouselIdx];

  const marketingBannerIdx = layoutComponents?.findIndex(
    (component) => component?.customRenderId === 'internalMarketingBanner'
  );
  const marketingBanner = layoutComponents[marketingBannerIdx];

  const style = {
    layoutRoot: 'sui-w-full sui-max-w-screen-2xl sui-m-auto sui-pb-12',
    layoutGrid: 'sui-grid sui-grid-flow-row-dense sui-gap-4 sui-grid-cols-12 sui-mx-4 lg:sui-mx-0'
  };

  return layoutComponents.length > 0 ? (
    <div id={layoutType} className={style.layoutRoot}>
      <LayoutSection
        layoutComponent={marketingBanner}
        index={marketingBannerIdx}
        components={components}
        componentSelectorData={componentSelectorData}
      />
      <LayoutSection
        layoutComponent={heroCarousel}
        index={heroCarouselIdx}
        components={components}
        componentSelectorData={componentSelectorData}
      />
      <div
        data-testid="layout-test"
        className={style.layoutGrid}
      >
        {layoutComponents.map((layoutComponent, index) => {
          if (
            !layoutComponent
            || !layoutComponent?.customRenderId
            || layoutComponent?.customRenderId === 'Hero Carousel'
            || layoutComponent?.customRenderId === 'internalMarketingBanner'
          ) return false;

          return (
            <LayoutSection
              layoutComponent={layoutComponent}
              index={index}
              components={components}
              componentSelectorData={componentSelectorData}
            />
          );
        })}
      </div>
      {(loading.server || loading.client) && LoadingLayout}
    </div>
  ) : <></>;
};

Layout.propTypes = {
  layoutType: oneOf(['themed-layout', 'default-layout']).isRequired,
  components: shape({
    [string]: element,
  }).isRequired,
  layoutComponents: arrayOf(shape),
  componentSelectorData: shape({
    variationIds: arrayOf(shape({ [string]: element })),
    renderDefault: bool,
    mboxes: arrayOf(string),
    campaignIds: arrayOf(shape({ [string]: element })),
    innervateTags: arrayOf(shape({ [string]: element }))
  }).isRequired,
  loading: shape({
    server: bool,
    client: bool,
  }),
  LoadingLayout: element,
};

Layout.defaultProps = {
  layoutComponents: [],
  loading: {
    server: false,
    client: false,
  },
  LoadingLayout: null,
};